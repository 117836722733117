import React from 'react';
import Nav from "../components/nav"
import ContentContainer from "../components/contentContainer"
import Projects from '../components/Projects'


export default () => (

  <ContentContainer>
    <Nav />
    <Projects />
  </ContentContainer>
)
